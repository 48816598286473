.BABVContactInfo {
  width: 100%;
  margin: 0 auto;
}

.BABVContactInfo--list {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 1.5rem 0;
  padding: 0;
}

.BABVContactInfo--list-item {
  margin: 0 0.9rem;
  text-align: right;
  width: 50%;
}

.BABVContactInfo--list-item:last-child {
  text-align: left;
}

.BABVContactInfo--list-item p {
  font-family: 'Built-Titling', Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  margin: 0;
}

.BABVContactInfo--list-item .Name {
  font-size: 1.4rem;
}

.BABVContactInfo--list-item a {
  color: #000;
  display: block;
  font-family: 'Abel-Regular', Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  text-decoration: none;
}

.BABVContactInfo--list-item a.Cel {
  font-size: 1.1rem;
}

.BABVContactInfo--address {
  font-family: 'Abel-Regular', Arial, Helvetica, sans-serif;
  font-size: 0.95rem;
  margin: 0;
}

@media (min-width: 900px) {
  .BABVContactInfo--list-item .Name {
    font-size: 1.7rem;
  }
}