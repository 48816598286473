.BABVLogo {
  align-items: center;
  color: #000;
  height: auto;
  position: relative;
  width: 100%;
  overflow: hidden;
  max-width: 100%;
}

.BABVLogo-image {
  animation: BABVLogo-image-spin infinite 5s linear;
  pointer-events: none;
  width: 80%;
}

.BABVLogo-title {
  font-family: 'Built-Titling', Arial, Helvetica, sans-serif;
  font-size: 4rem;
  font-weight: normal;
  left: 50%;
  margin: 0;
  padding: 0;
  position: absolute;
  pointer-events: none;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes BABVLogo-image-spin {
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }
  50% {
    opacity: 1;
    transform: rotate(180deg);
  }
  100% {
    opacity: 0;
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .BABVLogo-image {
    width: 31%;
  }
  
  .BABVLogo-title {
    font-size: 5rem;
  }
}

@media (min-device-width: 768px)
  and (max-device-width: 1024px) {
  .BABVLogo-image {
    width: 60%;
  }
}
