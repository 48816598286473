.BABVNav {
  width: 100%;
  height: auto;
}

.BABVNav--list {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.BABVNav--list-item {
  list-style: none;
  margin: 0 3.4rem;
}

.BABVNav--list-item a {
  font-family: 'Built-Titling', Arial, Helvetica, sans-serif;
  text-decoration: none;
  font-size: 2.4rem;
}

.BABVNav--list-item.Arqui a {
  color: rgb(70, 76, 102);
}

.BABVNav--list-item.Topo a {
  color: rgb(239, 122, 134);
}

.BABVNav--list-item.Diseno a {
  color: rgb(148, 176, 218);
}

.BABVNav--list-item.Ilustr a {
  color: rgb(107, 95, 167);
}

.BABVNav--footer {
  background-size: cover;
  background-image: url('../../assets/images/footer.png');
  height: 29px;
  width: 100%;
}

@media (min-width: 900px) {
  .BABVNav--list {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    position: relative;
    top: 12px;
    margin: 0;
  }
  
  .BABVNav--list-item a {
    font-size: 3.4rem;
  } 
}

@media (min-device-width: 768px)
  and (max-device-width: 1024px) {
  .BABVNav--list {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    position: relative;
    top: 8px;
    margin: 0;
  }

  .BABVNav--list-item {
    margin: 0 1.4rem;
  }

  .BABVNav--list-item a {
    font-size: 2.4rem;
  }
}